@import '../app/settings.scss';

figure {
    margin: 0;
    background-color: var(--background-light);

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.figure-person-icon,
    &.figure-location-icon {
        box-sizing: border-box;
        border-radius: 50%;

        &:not(.no-image) {
            // background: $spot;
            border: 2px solid $brand;

            img {
                height: 100%;
                width: 100%;
                border-radius: 50%;
            }
        }

        &.no-image {
            border: 2px solid $brand;
            display: flex;
        }

        svg {
            fill: $spot;
        }
    }

    &.figure-location-icon {
        svg {
            width: auto;
            height: 50%;
            fill: $brand;
        }
    }

    &.figure-person-icon {
        svg {
            width: 75%;
            height: auto;
        }

        &.no-image {
            align-items: flex-end;
        }
    }

    &.figure-person-icon {
        &.no-image {
            border: 2px solid $spot;
        }
    }

    &.figure-border-spot {
        svg {
            fill: $spot;
        }

        &:not(.no-image) {
            border: 2px solid $spot;
        }
    }

    &.figure-white-with-shadow {
        box-sizing: border-box;
        box-shadow: 0 0 4px rgba(black, 0.2);
        border-radius: 50%;

        &:not(.no-image) {
            border: 4px solid white;

            img {
                height: 100%;
                width: 100%;
                border-radius: 50%;
            }
        }

        &.no-image {
            border: 4px solid white;
        }
    }

    &.figure-border-none {
        border-width: 0 !important;
    }

    &.figure-border-success {
        border: 2px solid $success !important;

        svg {
            fill: $success;
        }

        &:not(.no-image) {
            border: 2px solid $success;
        }
    }
}
