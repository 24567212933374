@import './mixins/respond';

:root {
    --nav-height: 90px;
    --nav-mobile-height: 80px;
    --nav-menu-height: 90px;

    --main-padding: 40px;

    --gap: 30px;
    --gap-mobile: 15px;

    --brand: #96cb9e;
    --brand-rgb: 150, 203, 158;

    --spot: #e88d8d;
    --spot-rgb: 232, 141, 141;

    --family: #0090cf;
    --family-rgb: 0, 145, 245;

    --spot-darker: #c06f6f;

    --text: #23272a;
    --text-lighter: #a1aab0;

    --toastify-color-success: var(--brand);
    --toastify-icon-color-success: var(--brand);

    --background-light: #fbf5f9;
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

body {
    color: var(--text);
    line-height: 26px;
    font-size: 16px;
    background-color: #f2f9f3;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body,
button,
input,
select,
textarea {
    font-family: Rubik, sans-serif;
}

input,
textarea,
select {
    font-size: 16px !important; /* make sure it's not too small */

    &::placeholder {
        font-size: 14px;
    }
}

html,
body {
    height: 100%;
    overflow-x: hidden;
}

html,
html a,
button,
input {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

nav {
    position: sticky;
    z-index: 1;
    top: 0; // where to sticky
    left: 0;
    right: 0;
    width: 100%;
    height: var(--nav-height);

    background: white;
    box-shadow: 0 0 15px 3px rgb(#000000, 0.03);
}

.wrapper {
    display: flex;
    flex-direction: column;
    background-image: url('../svg/scribble.svg');
    background-repeat: repeat-x;
    background-position: top center;

    @include respond-to(mobile) {
        display: block;

        nav.topbar {
            position: relative;
            z-index: unset;
            padding-top: var(--safe-area-inset-top);
            height: unset;
        }

        nav.tabbar {
            position: fixed;
            bottom: 0;
            padding-bottom: calc(var(--safe-area-inset-bottom) - 20px); // do not make it negative
        }
    }
}

main {
    padding-top: var(--nav-height);
    padding-bottom: var(--main-padding);
    max-width: 1060px;
    margin: 0 auto;
    position: relative;
    width: calc(100vw - var(--main-padding) * 2);

    .inner {
        min-height: calc(100vh - (var(--nav-height) * 2) - var(--main-padding));
    }

    @include respond-to(mobile) {
        width: auto;
        padding-top: var(--gap-mobile);
        margin-left: var(--gap-mobile);
        margin-right: var(--gap-mobile);
        padding-bottom: var(--nav-menu-height);

        .inner {
            min-height: unset;
        }
    }
}

label[for] {
    cursor: pointer;
    user-select: none;
}

dialog {
    &:modal {
        margin: 0;
        border: none;
        max-width: unset;
        max-height: unset;
        border-radius: 5px;
        padding: 0;
        overflow: unset;
    }

    &::backdrop {
        background: rgba(var(--brand-rgb), 0.5);
    }
}

.familynet-is-loading {
    min-height: calc(100vh - 90px);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    color: #e88d8d;

    div {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    a {
        color: inherit;
    }
}

.loading-ripple {
    position: relative;
    width: 80px;
    height: 80px;

    div {
        display: block;
        position: absolute;
        border: 4px solid rgba(232, 141, 141, 0.33);
        opacity: 1;
        border-radius: 50%;
        animation: ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    div + div {
        animation-delay: -1s;
    }
}
