@use 'sass:color';

$black: #000;
$white: #fff;

$brand: #96cb9e !default;
$spot: #e88d8d !default;
$spot-lighter: color.adjust($spot, $lightness: 5%) !default;
$family: #0090cf !default;
$signal: color.adjust(#a7d3ae, $lightness: -5%) !default;

$brand-lighter: mix($brand, $white, 80%);
$brand-lightest: mix($brand, $white, 60%);
$brand-darker: color.adjust($brand, $lightness: 3.5%);
$brand-darkest: mix($brand, $black, 40%);

$fast: 300ms;

// bs style colors
$success: $signal;
$warning: #e66c2c; // orange
$danger: #d9534f;

$border-color: #e0e0e0;
$input-border-color: $spot;

$background: #eee !default;
$background-light: #f7f7f7;

$block-background: #fdfdfd;
$svg-fill: #dadada;

$whitespace: 40px;
$mobile-whitespace: 20px;

$text: #23272a;
$text-lighter: #727272;
$text-lightest: #c8c8c8;
$text-dark: black;

// font-weight medium
$medium: 500;

%medium {
    font-weight: $medium;
}

@import 'mixins/triangle';
@import 'mixins/ratio';
