@import '../app/settings.scss';

.action-menu {
    background: white;
    box-shadow: 0 2px 10px 0 rgba(black, 0.03);
    border-radius: 5px;
    margin: 0;
    padding: 5px 0;
    list-style: none;
    min-width: 180px;

    a,
    button {
        display: flex;
        align-items: center;
        padding: 12px 20px 11px;
        line-height: 14px;
        font-size: 12px;
        color: var(--text);
        font-weight: normal;
        text-align: left;

        svg {
            margin-right: 10px;
            height: 14px;
            width: auto;
        }

        &:hover {
            background: #f4faf5;
            text-decoration: none;
        }
    }
    button {
        width: 100%;
        box-sizing: border-box;
    }
}

.main-menu {
    list-style: none;

    li {
        background: #fef9f9;
        border-radius: 5px;
    }

    a {
        padding: 15px;
        display: flex;
        flex-direction: row;
        border-radius: 5px;
        transition: background-color 300ms;
        text-decoration: none;
        align-items: center;
        min-width: 280px;
        line-height: 20px;
        gap: 10px;
        color: $text;
        font-size: 14px;

        @include respond-to(mobile) {
            min-width: auto;
        }

        svg {
            fill: $spot;
            width: 20px;
            height: auto;
            margin-right: 10px;
        }

        &:hover {
            background-color: $spot;

            &,
            em {
                color: white;
            }

            svg {
                fill: white;
            }
        }
    }
}

.dropdown-menu {
    background: #fafcfa;
    box-shadow: 0 2px 10px 0 rgba(black, 0.03);
    border-radius: 5px;

    .main-menu {
        margin: 0;
        padding: 20px;
        background-color: white;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 10px;
        column-gap: 20px;
        border-radius: 0 0 5px 5px;
    }
}

.menu-header {
    padding: 20px 30px;
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 14px;
    line-height: 21px;

    figure {
        width: 40px;
        min-width: 40px;
        height: 40px;
    }

    strong {
        display: block;
        font-size: 16px;
    }
}
