@import '../app/settings.scss';

.loading-ripple {
    position: relative;
    width: 80px;
    height: 80px;
    div {
        display: block;
        position: absolute;
        border: 4px solid rgba($spot, 33%);
        opacity: 1;
        border-radius: 50%;
        animation: ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        & + div {
            animation-delay: -1s;
        }
    }

    &.loading-ripple-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@keyframes ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    3% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    97% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.loading-spinner {
    display: block;
    transform: translateZ(0);
    color: $border-color;
    font-size: 11px;
    text-indent: -99999em;
    margin: 55px auto;
    position: relative;
    width: 10em;
    height: 10em;
    box-shadow: inset 0 0 0 1em;

    &,
    &:before,
    &:after {
        border-radius: 50%;
    }

    &:before,
    &:after {
        position: absolute;
        content: '';
    }

    &:before {
        transform-origin: 5.2em 5.1em;
        animation: loading-spinner-animation 2s infinite ease 1.5s;
        width: 5.2em;
        height: 10.2em;
        background: $background;
        border-radius: 10.2em 0 0 10.2em;
        top: -0.1em;
        left: -0.1em;
    }

    &:after {
        transform-origin: 0px 5.1em;
        animation: loading-spinner-animation 2s infinite ease;
        width: 5.2em;
        height: 10.2em;
        background: $background;
        border-radius: 0 10.2em 10.2em 0;
        top: -0.1em;
        left: 5.1em;
    }

    &.on-white {
        &:before,
        &:after {
            background: white;
        }
    }

    &.on-light {
        &:before,
        &:after {
            background: var(--background-light);
        }
    }

    &.on-brand {
        &:before,
        &:after {
            background: $brand;
        }
    }

    &.on-action,
    &.on-success {
        &:before,
        &:after {
            background: $success;
        }
    }

    &.loading-spinner-small {
        margin: 0 0 0 6px;
        width: 18px;
        height: 18px;
        box-shadow: inset 0 0 0 3px;
        display: inline-block;
        vertical-align: text-bottom;
        top: -1px;

        &:before {
            transform-origin: 9px 9px;
            width: 9px;
            height: 18px;
            border-radius: 9px 0 0 9px;
            top: 0;
            left: 0;
        }

        &:after {
            transform-origin: 0px 9px;
            width: 9px;
            height: 18px;
            border-radius: 0 18px 18px 0;
            top: 0;
            left: 9px;
        }
    }

    button & {
        margin-left: 0px;
        margin-right: 8px;
    }
}

@-webkit-keyframes loading-spinner-animation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes loading-spinner-animation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loading-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - (var(--nav-height) * 2) - var(--main-padding));

    a,
    & {
        color: $spot;
    }
}

.loading-icon {
    position: relative;
    display: flex;
    width: 40px;
    height: 40px;

    align-items: center;
    justify-content: center;

    svg {
        fill: var(--spot);
        width: 40%;
        height: auto;
    }

    .loading-ring {
        position: absolute;
        border: 2px solid var(--spot);
        border-bottom-color: transparent;
        border-radius: 80px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        animation: ring 1s linear infinite;
    }
}
@keyframes ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.splash-page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - (var(--nav-height) * 2) - var(--main-padding));
    background-color: var(--spot);

    svg {
        width: 128px;
        margin-bottom: 30px;
        height: auto;
    }
}

body:has(.splash-page) {
    background: var(--spot);
}
